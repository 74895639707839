<!-- 预览页面 -->
<template>
    <div class="previewPage">
        <!-- 轮播图 -->
        <div class="swiper-wrap" >
            <el-carousel height="200px" class="swiper">
                <el-carousel-item v-for="(item,index) in swiper" :key="index">
                    <el-image class="images" :src="item.url" ></el-image>
                </el-carousel-item>
            </el-carousel>
            <div class="swiper-list">
                <div class="swiper-item" v-for="(item,index) in swiper" :key="index">
                    <el-image class="images" :src="item.url" ></el-image>
                </div>
            </div>
        </div>
        <!-- 商品价格 -->
        <div class="goods-detail">
            <div class="detail-name">{{goodsInfo.goodsName}}</div>
            <div class="detail-content">{{goodsInfo.description}}</div>
            <div class="detail-wrap">
                <div class="detail-price">￥<div class="price">{{goodsInfo.price}}</div>元</div>
                <div class="detail-sharp">
                    <div class="sharp-item" @click="clickCollection()">
                        <el-image  class="sharp-img" :src="collections"></el-image>
                        <!-- <el-image v-else class="sharp-img" src="@/assets/images/collection-n.png"></el-image> -->
                        <div v-if="collection">已收藏</div>
                        <div class="btn" v-else>收藏</div>
                    </div>
                    <el-button open-type="share" class="sharp-item share-item" >
                        <el-image class="sharp-img share" :src="share"></el-image>
                        <div class="btn">分享</div>
                    </el-button>
                </div>
            </div>
        </div>
        <!-- 详情 -->
        <div class="detail-info-wrap">
            <div class="tab-tilte">
                <div @click="cur=0" class="tab-item" :class="{active:cur==0}">详情图</div>
                <div @click="cur=1" class="tab-item" :class="{active:cur==1}">配件</div>
                <div @click="cur=2" class="tab-item" :class="{active:cur==2}">规格性能</div>
                <div @click="cur=3" class="tab-item" :class="{active:cur==3}">图纸</div>
            </div>
            <div class="tab-content">
                <!-- 详情图 -->
                <div v-show="cur==0">
                    <div class="info-img-wrap">
                        <!-- <video style="width: 100%;height: 400upx;" :src="video" :poster="poster"></video> -->
                        <div class="info-img" v-for="(item,index) in infoList" :key="index">
                            <el-image class="img-item" :src="item.url" mode="widthFix" lazy-load></el-image>
                        </div>
                    </div>
                </div>
                <!-- 配件 -->
                <div v-show="cur==1">
                    <div class="drawings-wrap">
                        <div class="product-item" v-for="(item,index) in accessories" :key="index">
                            <div class="product-img">
                                <el-image class="img" :src="item.imageUrlList[0].url" mode="aspectFill" lazy-load></el-image>
                            </div>
                            <div class="product-name">{{item.name}}</div>
                        </div>
                    </div>
                </div>
                <!-- 规格性能 -->
                <div v-show="cur==2">
                    <div class="info-detail-wrap">
                        <div class="info-title">
                            <div class="big-title">结构及工艺特性</div>
                            <div class="sm-title">Structure and process characteristcs</div>
                        </div>
                        <div class="info-content">
                            <div class="info-list" v-for="(item,index) in structure" :key="index">
                                <div class="list-item">{{item.value}}</div>
                            </div>
                        </div>
                        <div class="info-title">
                            <div class="big-title">产品性能</div>
                            <div class="sm-title">Product perfomance</div>
                        </div>
                        <div class="info-content">
                            <div class="info-table" v-for="(item,index) in perfomance" :key="index">
                                <div class="table-th">{{item.property}}</div>
                                <div class="table-td">{{item.perfomance}}</div>
                            </div>
                        </div>
                        <!-- <div class="info-title">
                            <div class="big-title">规格图片</div>
                        </div>
                        <div class="info-img" v-for="(item,index) in specDetail" :key="index">
                            <el-image class="img-item" :src="item" mode="widthFix" lazy-load></el-image>
                        </div> -->
                    </div>
                </div>
                <!-- 图纸 -->
                <div v-show="cur==3">
                    <div class="drawings-wrap" v-if="product.length >0">
                        <div class="product-item" v-for="(item,index) in product" :key="index">
                            <div class="product-img">
                                <el-image class="img" :src="item.url" mode="aspectFill" lazy-load></el-image>
                            </div>
                            <div class="product-name">{{item.fileName}}</div>
                        </div>
                    </div>
                    <div v-else class="drawings-n">暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            cur: 0,
            collection: false,
            swiper: [],
            infoList: [],
            accessories: [],
            structure: [],
            perfomance: [],
            product: [],
            specDetail: [],
            goodsInfo: {},
            share: require("../images/share.png"),
            collections: require("../images/collection-n.png"),
        };
    },
    created() {
        this.goodsInfo.goodsName = '商品名称';
        this.goodsInfo.des = '商品描述';
        this.goodsInfo.price = 0;
    },
    methods: {
        setDetail(data){
            console.log("123",data);
            this.infoList = data.goodsDetail;
            this.structure = data.textForm.contentTextList;
            this.perfomance = data.tableForm.contentTableList;
            data.drawingFrom.forEach(v => {
                v.fileName = v.fileName.substring(0,v.fileName.indexOf("."));
            })
            this.product = data.drawingFrom;
            this.specDetail = data.specDetail;
            this.accessories = data.accessoriesImgIdList
        },
        setValue(value){
            this.goodsInfo = value
            this.swiper = value.imageUrlList
        }
    },
}

</script>
<style lang='scss' scoped>
.previewPage{
    .swiper-wrap{
        .swiper{
            background: #eee;
        }
        .swiper-list{
            display: flex;
            flex-flow: wrap;
            align-items: center;
            .swiper-item{
                margin: 10px 5px;
                height: 40px;
                width: 40px;
                background: #eee;
                .images{
                    height: 100%;
                    width: 100%; 
                }
            }
        }
    }
    .goods-detail{
        padding: 0px 10px;
        border-bottom: 1px solid #eee;
        .detail-name{
            font-size: 16px;
            color: #000;
        }
        .detail-content{
            font-size: 14px;
            color: #999;
            padding-top: 5px;
            width:100%;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .detail-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .detail-price{
                font-size: 12px;
                color: #fb5966;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                .price{
                    font-size: 20px;
                    font-weight: bold;
                    padding: 0 5px;
                }
            }
            .detail-sharp{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .sharp-item{
                    margin-left: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: none;
                    outline: none;
                    background-color: #fff;
                    .btn{
                        font-size: 12px;
                        color: #000;
                        margin-top: 5px;
                    }
                    .sharp-img{
                        width: 20px;
                        height: 20px;
                    }
                }
                .share-item{
                    margin-top: 5px;
                    .share{
                        margin-bottom: -0px;
                    }
                    .btn{
                        font-size: 12px;
                    }
                }
                button::after { border: none }
            }
        }
    }
    .detail-info-wrap{
        .tab-tilte{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            .tab-item{
                padding: 10px;
                font-size: 14px;
            }
            .active{
                color: #000;
                font-weight: bold;
                position: relative;
            }
            .active:after{    
            position: absolute;    
            height: 5px;    
            width: 22px;
            border-radius: 4px;
            left: 50%;
            transform: translate(-50%,-50%);
            bottom: 0;
            content: '';    
            background-color: #141a60;
            }
        }
        .tab-content{
            padding: 0 10px;
            .info-img-wrap{
                padding: 10px 0;
                .info-img{
                    .img-item{
                        width: 100%;
                        height: 100%;
                        padding-bottom: 10px;
                    }
                }
            }
            .info-detail-wrap{
                padding: 10px 5px;
                .info-title{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: baseline;
                    .big-title{
                        font-size: 14px;
                        color: #333;
                        font-weight: bold;
                    }
                    .sm-title{
                        font-size: 12px;
                        color: #333;
                        margin-left: 5px;
                    }
                }
                .info-img{
                    margin-top: 10px;
                }
                .info-content{
                    padding: 20px 0;
                    .info-list{
                        .list-item{
                            line-height: 20px;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                    .info-table{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-top: 1px solid #545455;
                        border-left: 1px solid #545455;
                        border-right: 1px solid #545455;
                        font-size: 14px;
                        width: 100%;
                        .table-th{
                            width: 30%;
                            border-right: 1px solid #545455;
                            padding: 5px;
                            overflow: hidden;
                        }
                        .table-td{
                            width: 68%;
                            padding: 5px;
                        }
                    }
                    .info-table:last-child{border-bottom: 1px solid #545455;}
                }
            }
            .drawings-wrap{
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                box-sizing: border-box;
                margin-top: 10px;
                width: 100%;
                .product-item{
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 10px;
                    width: calc((100% - 10px)/2);
                    .product-img{
                        width: 100%;
                        height: 100px;
                        background-color: #eee;
                        border-radius: 6px;
                        overflow: hidden;
                        .img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .product-name{
                        font-size: 14px;
                        padding: 10px 0px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap;
                    }
                }
            }
            .drawings-n{
                font-size: #999;
                text-align: center;
                padding: 20px 0;
            }
        }
    }
}
</style>