<template>
    <el-button size='small' type="primary" @click="handleClick">{{name}}</el-button>
</template>

<script>
export default {
    props: {
        name:{
            type:String,
            default:'新建'
        }
    },
    methods: {
        handleClick(){
            this.$emit('cbCreateBtnClick')
        }
    }
}
</script>

<style>

</style>