<template>
    <div class="contenWrap">
        <div class="contenWrap-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.contenWrap{
    width: 100%;
    // height: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
    background-color: #F4F6F9;
    // display: flex;
    // flex-direction: column;
    .contenWrap-container{
        width: 100%;
        // height: 100%;
        // border: 1px solid blue;
        box-sizing: border-box;
        // display: flex;
        // justify-content: center;
        // min-width: 1000px;
        // padding: 0 20px 20px 20px;
        padding: 20px 20px;
    }
}
</style>